.loginForm {
    margin-top: 33px;
}
.btnLogin {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.forgetPwd {
    font-size: 14px;
    font-weight: 400;
    color: #BDC0C8;
}