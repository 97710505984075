.search {
  padding: 20px;
}

.searchWidth {
  width: 150px;
}

.searchRight {
  text-align: right;
}

.searchFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;
}

.rowSpace {
  padding: 20px 20px 0;
}

.tableSpace {
  padding: 0 20px;
}

.tagTableSpace {
  padding: 10px 20px 0;
}

.statusNormal {
  color: #777777;
  padding-left: 10px;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #777777;
    position: absolute;
    left: 0;
    top: 40%;
  }
}

.statusGreen {
  color: #28b571;
  &::before {
    background: #28b571;
  }
}

.statusYellow {
  color: #fdb62b;
  &::before {
    background: #fdb62b;
  }
}

.statusRed {
  color: #f02f2f;
  &::before {
    background: #f02f2f;
  }
}

.tab {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-tab {
      margin-left: 0.32rem;
    }
  }
}

/*
单行显示，超出...
*/
.ellipse {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableRow {
  cursor: pointer;
}

.progressItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .progressItemInfo {
    margin-left: 10px;
  }
}

.subjects {
  padding: 20px 0 16px 20px;
}

.radioTagWrapper {
  padding: 0px 32px 0px 20px;
  margin-bottom: 16px;
}

.rule {
  display: flex;
  align-items: center;
  gap: 8px;
  .name {
    flex-shrink: 0;
  }
  .desc {
    color: #abb8ce;
    margin-left: 10px;
    max-width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
