.main {
  width: 100%;
  border: 1px solid #eee;
  overflow: scroll;
  height: calc(100vh - 270px);
}
.mainTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  border-bottom: 1px solid #eee;
  .mainTitleAdd {
    cursor: pointer;
  }
}
.mainSearch {
  padding: 8px 10px;
}
.tree {
  width:100%;
  overflow: auto;
  min-height: 400px;
  :global {
    .ant-tree-treenode {
      width: 100%;
    }
    .ant-tree-node-content-wrapper {
      width:100%;
    }
  }
}
.treeItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  .itemWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .treeIcon {
    display: none;
  }
  &:hover {
    .treeIcon {
      display: block;
    }
  }
}
.badgeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.modalWidth {
  width: 700px !important;
}

.tagTitle {
  :global {
    .ant-descriptions-header {
      margin-bottom: 10px;
    }
    .ant-descriptions-title {
      font-size: 15px;
      font-weight: normal;
      padding-left:10px;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 2px;
        height: 16px;
        background: #00AFEC;
        position: absolute;
        left:0;
        top: 5px;
      }
    }
  }
}

.tagTitleTop {
  margin-top: 30px;
}

.tagText {
  background: #fafafa;
  padding: 20px;
}

.iconText {
  color: #FAAD14;
}

.itenIcon {
  color: #96EEFF;
}

.treeBox {
  padding: 6px 6px;
  min-height: 400px;
  overflow: auto;
  .treeSpin {
    padding-top: 30%;
    text-align: center;
  }
}

.over {
  overflow: hidden;
}

.nodeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  &>button {
    display: none;
  }
  &:hover button{
    display: block;
  }
  .copy {
    margin-left: 16px;
  }
}
