.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    display: flex;
    align-items: center;
    gap: 38px;
    .invalidText {
      position: relative;
      &::before {
        width: 6px;
        height: 6px;
        content: '';
        border-radius: 50%;
        background: #c2c2c2;
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.form {
  margin-top: 24px;
}

.audit {
  &.wait {
    color: #000000;
  }
  &.success {
    color: #00b42a;
  }
  &.fail {
    color: #ff4d4f;
  }
}

.invalid {
  color: #c2c2c2;
}
.isDelete {
  color: #abb8ce !important;
  text-decoration: line-through !important;
  .audit {
    color: #abb8ce !important;
  }
}
