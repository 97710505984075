
.titleWrapper {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0,0,0,0.85);
  line-height: 24px;

  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding-left: 16px;
}
.section {
  padding: 16px;
}

.bold{
font-weight: bold;
}