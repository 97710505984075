.search {
  padding: 0 0 16px;
  display: flex;
  justify-content: space-between;
}

.searchWidth {
  width: 150px;
}

.spaceTab {
  padding: 0px 20px 28px;
}

.rowSpace {
  padding: 20px 20px 0;
}

.spaceRight {
  display: inline-block;
  padding-right: 30px;
}

.statusGreen {
  color: #28b571;
}

.statusRed {
  color: #f02f2f;
}

.tagBox {
  border: 1px solid #ebebeb;
  padding: 15px 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.tagIcon {
  font-size: 12px;
  color: #fff;
  padding: 4px 8px;
  background: #00afec;
  border-radius: 8px;
  margin-right: 24px;
}

.tagIconBlue {
  color: #00afec;
  background: #bff6ff;
}

.tagIconGreen {
  color: #389e0d;
  background: #d9f7be;
}

.taglabel {
  font-size: 16px;
  font-weight: bold;
  margin-right: 24px;
  margin-top: -2px;
}
.tagNumbet {
  font-size: 18px;
  font-weight: bold;
  margin-top: -1px;
}

.tab {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-tab {
      margin-left: 0.32rem;
    }
  }
}

/*
单行显示，超出...
*/
.ellipse {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.diagramContent {
  display: flex;
  flex-direction: row;
}

.fieldList {
  padding: 16px;
  .formDetail {
    margin-bottom: 24px;
    span {
      margin-right: 16px;
    }
  }
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.enable {
  color: #52c41a;
}
.disable {
  color: #ff4d4f;
}
