.loginForm {
    margin-top: 8px;
}
.btnLogin {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.forgetPwd {
    font-size: 14px;
    font-weight: 400;
    color: #BDC0C8;
}
.snsCode {
    display: flex;
}
.codeBtn {
    margin-left: 9px;
    font-size: 14px;
    color: #1CC2FD;
    border-color: #1CC2FD;
}
.forgetPwdTitle {
    font-size: 23px;
    font-weight: bold;
    color: #0F121B;
    margin-bottom: 33px;
}

/*  popover */
.popoverWrapper {
    width: 240px;
}
.popoverProgress {
    margin-bottom: 10px;
}
.error {
     color: #ff0000;
 }

.warning {
     color: #ff7e05;
 }

.success {
     color: #52c41a;
 }