.confirmModal {
  :global {
    // modal
    .ant-modal-header {
      border-bottom: none;
      padding: 32px;
      .ant-modal-title {
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .ant-modal-body {
      padding: 0 32px;
    }

    .ant-modal-footer {
      border-top: none;
      padding: 32px;
      button {
        width: 112px;
      }
      .ant-btn-default {
        border: 1px solid #1bbee1;
        color: #1bbee1;
      }
      .ant-btn-primary {
        margin-left: 16px !important;
      }
    }

    .ant-modal-close {
      color: #031f47;
      top: 20px;
      right: 12px;
    }
  }
}
