.section {
  background: #fafafa;
  padding: 16px 0px 0px 0px;
  border-bottom: 1px solid #fafafa;
}

.option {
  background: #fafafa;
  padding: 16px 0px 0px 0px;
  border-bottom: 1px solid #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    flex: 1;
  }
  .right {
    width: 50px;
    padding-right: 16px;
  }
}

.add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 16px;
}
