.main {
  width: 100%;
  border: 1px solid #eee;
  overflow: scroll;
  height: calc(100vh - 270px);
}
.mainTitle {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  border-bottom: 1px solid #eee;
}
.mainSearch {
  padding: 8px 10px;
}
.tree {
  width:100%;
  overflow: auto;
  min-height: 400px;
  :global {
    .ant-tree-treenode {
      width: 100%;
    }
    .ant-tree-node-content-wrapper {
      width:100%;
    }
  }
}


.treeBox {
  padding: 6px 6px;
  min-height: 400px;
  overflow: auto;
}

