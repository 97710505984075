
.btnItem {
    background: linear-gradient(180deg, #FFFFFF 24%, #ECECEC 100%);
    opacity: 1;
    border-radius: 0;
    border: 1px solid #D9D9D9;
    align-items: center;
    justify-content: center;
    display: flex;
}
.iconImg {
    width: 16px;
    height: 16px;
}

.dividerLine {
    color: #00AFEC;
}

.cardWrapper {
    //background: rgb(242, 244, 245);
    margin-bottom: 10px;
    .ant-card-body {
        padding: 0;
    }
}

.codemirror {
    &>div {
        min-height: 150px;
    }
    min-height: 150px;
    border: 1px solid #eaeaea;
}