
.content {
  margin: 0px 15px 10px 15px;
  position: relative;
}
.newBtn {
  position: absolute;
  right: 0;
  top: 7px;
}
/*
单行显示，超出...
*/
.ellipse {
  width: 300px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.actionBtns {
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerPage {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}
.headerPageTitle {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
}
.searchWrapper {
  height: 56px;
}
.searchTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  color: rgba(0,0,0,0.85);
  line-height: 22px;
}