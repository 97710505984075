
.search {
  padding: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchTotal {
  font-size: 14px;
  font-weight: 500;
  color: #3D3D3D;
  line-height: 22px;
}