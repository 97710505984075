.btn {
  text-align: center;
  font-size: 14px;
  color: #031f47;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 14px;
  color: #687991;
  margin-top: 4px;
}

.error {
  color: #f5222d;
}

.title{
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.downloadWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  &>button {
    padding: 0;
  }
}
