
.collapse {
    margin-left: 20px;
}
.dropdown {
    margin-left: 6px;
}
.dropdownIcon {
    margin-left: 6px;
}
.actionIcon {
    margin-right: 6px;
}