
.searchBox {
  padding: 8px 20px 0;
  .searchBoxRight {
    display: flex;
    justify-content: flex-end;
    //align-items: center;
  }
}

.content {
  //padding: 24px;
  margin-left: 16px;
  margin-right: 16px;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .tableTitle {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    color: rgba(0,0,0,0.85);
  }
}
.divider {
  height: 16px;
  width: 2px;
  background: #00AFEC ;
  margin-left: 0;
}

.btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.016);
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #00AFEC;
  color: #00AFEC;

  span {
    color: #00AFEC;
  }
}
.filterWrapper {
  position: absolute;
  right: 0;
  padding-left: 12px;
  height: 292px;
  width: 140px;
}
.ant-drawer-body {
  padding: 0 0 0 12px !important;
}
