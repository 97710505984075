.btn {
  text-align: center;
  font-size: 14px;
  color: #031f47;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 14px;
  color: #687991;
  margin-top: 4px;
}

.error {
  color: #f5222d;
}

.addIcon {
  color: #031f47;
  margin-right: 5px;
  width: 20px;
  height: 0;
}
