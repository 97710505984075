.status {
  padding: 8px 8px 0 8px;

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 8px;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .content {
    padding-left: 38px;
    .errorTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.45);
      .errorItemTitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-top: 8px;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
