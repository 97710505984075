.header {
  justify-content: flex-end;
  margin-bottom: 20px;
}

.container {
  padding: 0 20px;
}

.drugHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
