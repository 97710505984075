.wrapper {
  padding: 20px;
}

.label {
  display: flex;
  align-items: center;
}
.exist {
  width: 4px;
  height: 4px;
  background-color: #f53f3f;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
}
