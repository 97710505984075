
.wrapper {
    width: 100%;
}
.btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.badge {
    width: 100%;
}
.dicTree {
    //height: 50vh;
  min-height: 100%;
}

.searchForm{
    display: flex;
    align-items: center;
}

.searchForm span{
    width:60px;
    margin-right: 10px;
}

.nodeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  &>button {
    display: none;
  }
  &:hover button{
    display: block;
  }
  .copy {
    margin-left: 16px;
  }
}
