.loginWrapper {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(226deg, #E0F8FE 0%, #D4EDFF 100%);
}
.center {
    display: flex;
    background: #fff;
    width: 960px;
    height: 540px;
}
.left {
    width: 480px;
}
.bgLeft {
    display: flex;
    flex: 1;
    width: 100%;
}
.right {
    width: 480px;
    padding-left: 86px;
    padding-top: 42px;
    padding-right: 77px;
}
.rightHeader {
    /*margin-bottom: 33px;*/
}
.imgLogo {
    width: 128px;
    height: 25px;
}
.headerTitle {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    color: #091326;
    margin-top: 46px;
    margin-bottom: 15px;
}
.headerSubTitle {
    font-size: 23px;
    line-height: 23px;
    font-weight: bold;
    color: #091326;
}