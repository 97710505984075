.patientInfo {
  display: flex;
  padding: 16px;
  background-color: #fff;
  flex-wrap: wrap;
  gap: 20px;
  .section {
    display: flex;
    width: 36%;
    font-weight: 400;
    font-size: 14px;
    .label {
      width: 120px;
      color: #031f47;
      margin-right: 20px;
    }
    .value {
      color: #687991;
    }
  }
}

.extraInfo {
  padding: 16px;
  .section {
    font-weight: 400;
    font-size: 14px;
    margin-top: 24px;
    .label {
      color: #031f47;
    }
    .value {
      color: #687991;
    }
  }
}

.tabContent {
  padding: 10px;
  font-size: 11pt;
  line-height: 1.6;
  letter-spacing: 1px;
  span {
    font-weight: bold;
    margin-right: 4px;
  }
}

.tableContainer {
  overflow: auto;
  overflow-y: hidden;
  .header {
    display: flex;
    align-items: center;
    color: #687991;
    border: 1px solid #f0f0f0;
    background: #fafbfb;
    margin-bottom: 8px;
    .headerItem {
      flex-shrink: 0;
      padding: 9px 16px;
      border-right: 1px solid #f0f0f0;
      &:last-child {
        border-right: none;
      }
    }
  }
}

.recordCard {
  margin-bottom: 12px;
}
.cardHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 9px 16px;
  border: 1px solid #f0f0f0;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #031f47;
  }
  .item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 8px;
    background: #fafbfb;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    color: #687991;
  }
}

.cardMain {
  .labItem {
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-top: none;
    .labItemCell {
      padding: 9px 16px;
      color: #687991;
      border-right: 1px solid #f0f0f0;
      &:last-child {
        border-right: none;
      }
    }
  }
}

.content {
  a {
    color: #031f47;
  }
}
