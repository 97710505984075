.detailCard {
  display: flex;
  flex-wrap: wrap;
  .column {
    width: 33.3333%;
    height: 46px;
    border: 1px solid #e9eaec;
    background-color: #fff;
    display: flex;
    margin: 0 -1px -1px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 46px;
    .label {
      width: 120px;
      height: 100%;
      background-color: #fafbfb;
      border-right: 1px solid #e9eaec;
      color: #687991;
      padding-left: 16px;
    }
    .value {
      padding-left: 16px;
      color: #031f47;
    }
  }
}
