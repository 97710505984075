

.radioTagWrapper {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: -24px;
  height: 64px;
  .radioTagArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    //margin-bottom: 16px;
    overflow-x: scroll;
    .radioTag {
      position: relative;
      margin-right: 16px;
      .itemWrapper {
        .tagWrapper {
          display: flex;
          flex-direction: row;
          border: 1px solid #D9D9D9;
          padding: 5px 12px;
          min-width: 150px;
        }
      }
      .icon {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 16px;
        height: 16px;

      }
    }
  }
  .addIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 64px;
    background-color: #fff;
    padding: 0px 20px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    .btn {
      width: 32px;
      height: 32px;
    }
  }
}


