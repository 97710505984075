
.siteBg{
    background: #fff;
    width: 200px;
}

.sideLogo {
    height: 64px;
    background: #00AFEC;
    display: flex;
    justify-content: center;
    line-height: 64px;

    font-size: 30px;
    font-weight: 500;
    color: #FFFFFF;
}
.sideLogoImg {
    width: 32px;
    height: 32px;
    align-self: center;
    margin-right: 5px;
}
