

.pageWrapper {
  margin-left: 20px;
}





.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.select {
  :global {
    .ant-select-selection-item-remove {
      display: none;
    }
  }
}

.labelBox {
  border: 1px solid #eee;
  .labelTitle {
    background:#eee;
    padding:8px 16px;
    color: #333;
  }
}

.labelMain {
  width:100%;
  padding: 16px;
  height: 500px;
  overflow: auto;
  .labelList {
    border-bottom: 1px solid #efefef;
    padding: 6px 0;
    cursor: pointer;
    user-select: none;
    &:last-child {
      border: none;
    }
  }
  .labelRemove {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    .labelIcon {
      cursor: pointer;
      padding: 0 0 0 10px;
    }
  }
}

.btnGroup {
  padding: 20px;
}
