.divider {
  margin-top: 0px;
  margin-bottom: 16px;
}

.icdTree {
  width: 100%;
  border: 1px solid #eee;
  overflow: auto;
  padding: 16px 20px 16px 10px;
  height: 100%;
}
.search {
  padding: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchTotal {
  font-size: 14px;
  font-weight: 500;
  color: #3D3D3D;
  line-height: 22px;
}

.tableSpace {
  padding: 0 20px;
}
.isIcd {
  // height: calc(100vh - 334px);
}
.leftArea {
  margin-top: 20px;
}

.icdTitle {
  font-size: 14px;
  padding: 6px 20px;
  background: #FAFAFA;
}

.icdSpace {
  //padding: 20px 30px;
  display: flex;
  flex-direction: row;
}

.over {
  overflow: hidden;
}

// 字典映射
.main {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
}

.customCollapse {
  :global {
    .ant-collapse-borderless {
      background: #f0f2f5;
    }
    .ant-collapse-item {
      margin-top: 8px;
      overflow: hidden;
      background: #fff;
      border: 0px !important;
    }
  }
}

.searchWidth {
  width: 130px !important;
}

.tabWrap {
  padding: 0 20px;
}

.statusColor {
  padding-left: 16px;
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -3px;
  }
  &.statusIn {
    color: #FFCF8B;
    &::before {
      background: #FFCF8B;
    }
  }
  &.statusSuccess {
    color: #00B42A;
    &::before {
      background: #00B42A;
    }
  }
  &.statusFail {
    color: #FF4D4F;
    &::before {
      background: #FF4D4F;
    }
  }
  &.statusGary {
    color: #ABB8CE;
    &::before {
      background: #ABB8CE;
    }
  }
}

.tableGary {
  color: #ABB8CE;
}

.auditBtn {
  color: '#00B42A';
  border-color: '#00B42A';
}
