/* 自定义弹窗动画效果 */
.custom-slide-up-enter,
.custom-slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.custom-slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.custom-slide-up-enter.custom-slide-up-enter-active,
.custom-slide-up-appear.custom-slide-up-appear-active {
  -webkit-animation-name: customSlideUpIn;
  animation-name: customSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.custom-slide-up-leave.custom-slide-up-leave-active {
  -webkit-animation-name: customSlideUpOut;
  animation-name: customSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.custom-slide-up-enter,
.custom-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.custom-slide-up-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes customSlideUpIn {
  0% {
    transform: translateY(-50px);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes customSlideUpIn {
  0% {
    transform: translateY(-50px);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes customSlideUpOut {
  0% {
    transform: translateY(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes customSlideUpOut {
  0% {
    transform: translateY(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
