.select {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding-right: 50px;
  height: 36px;
  overflow: hidden;
  position: relative;
  &.expand {
    height: auto;
  }
  .item {
    padding: 0 12px;
    border-radius: 4px;
    flex-shrink: 0;
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    &.active {
      background: rgba(0, 175, 236, 0.2);
      color: #1890ff;
    }
  }
  .trigger {
    position: absolute;
    right: 0;
    top: 0;
    height: 32px;
    line-height: 32px;
    display: block;
  }
}
