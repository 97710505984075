.headerBg {
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 27px;
}
.trigger {
}
