.main {
  padding: 0 20px;
}
.searchBox {
  padding: 8px 0;
  .searchBoxRight {
    display: flex;
    justify-content: flex-end;
  }
}

.status {
  display: flex;
  align-items: center;
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    &.success {
      background-color: #00afec;
    }
    &.fail {
      background-color: #c2c2c2;
    }
  }
}

.audit {
  &.wait {
    color: #000000;
  }
  &.success {
    color: #00b42a;
  }
  &.fail {
    color: #ff4d4f;
  }
  &.sFail {
    color: #c2c2c2;
  }
}
