.divider {
  margin: 0;
}

.search {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableSpace {
  padding: 0 20px;
}

.icdTitle {
  font-size: 14px;
  line-height: 32px;
}

.icdSpace {
  padding: 20px 30px;
}

.over {
  overflow: hidden;
}

// 字典映射
.main {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
}

.customCollapse {
  :global {
    .ant-collapse-borderless {
      background: #f0f2f5;
    }
    .ant-collapse-item {
      margin-top: 8px;
      overflow: hidden;
      background: #fff;
      border: 0px !important;
    }
  }
}

.searchWidth {
  width: 130px !important;
}

.selectedRow {
  background: #1cc2fd;
}

.searchArea {
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background: #fafafa;
  padding: 8px 12px;
  margin-bottom: 10px;
}