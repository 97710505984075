body {
  font: 14px 'Century Gothic', Futura, sans-serif;
  margin: 20px;
}

ol,
ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}
/* 画布组件样式 Start */
.diagram-component {
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  background-color: white;
}
.diagram-gojs {
  width: 100%;
  height: calc(100vh - 107px - 72px - 140px);
  border: 1px solid #d9d9d9;
  background-color: white;
}

.myDiagramDiv {
  flex-grow: 1;
  height: 620px;
  border: 1px solid #d9d9d9;
  border-top-width: 0;
  position: relative;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: auto;
}
.palette-component {
  height: 100%;
}

.flowWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}
.paltteWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  align-items: center;
  height: 88px;
}
.palette-gojs,
.diagram-gojs,
.palette-component,
.diagram-component {
  position: relative;
}
.btnGroup {
  /*padding-right: 20px;*/
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 8px;
  top: 160px;
  z-index: 999;
}
/* 画布组件样式 End */
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 0;
}

.head-title {
  font-size: 16px;
  padding: 12px 0;
  font-weight: bold;
  margin: 0;
}

.headmin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: #fff;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

/* 被选中的表格行的样式 */
.clickRowStyle {
  background-color: #E6FCFF;
}
.ant-table-tbody > .clickRowStyle:hover > .ant-table-cell-row-hover {
  background-color: #E6FCFF;
}

/*.ant-tree-node-content-wrapper {*/
/*  -moz-user-select:auto;*/
/*  -webkit-user-select:auto;*/
/*  user-select:auto !important;*/
/*}*/

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
  border-color: #262626;
  color: #262626;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
  border-right-color: #262626 !important;
}
.ant-radio-button-wrapper:hover{
  color: #3D3D3D !important;
}


// 纳排标签统一样式
.include-label {
  padding: 2px 12px;
  border-radius: 2px;
  background-color: #edf1f8;
  font-weight: 400;
  font-size: 12px;
  color: #2a509a;
  cursor: pointer;
  &.selector:hover {
    background-color: #e0e8f2;
  }
  &.active-label {
    background-color: #5d7ea5;
    color: #fff;
  }
}

// 排除标签统一样式
.include-rid-label {
  padding: 2px 12px;
  border-radius: 2px;
  background-color: #f0f1f1;
  font-weight: 400;
  font-size: 12px;
  color: #4a4c4e;
  cursor: pointer;
  &.selector:hover {
    background-color: #e0e8f2;
  }
  &.active-rid-label {
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }
}

.exclude-label {
  padding: 2px 12px;
  border-radius: 2px;
  background-color: #f0f1f1;
  font-weight: 400;
  font-size: 12px;
  color: #4a4c4e;
  cursor: pointer;
  &.selector:hover {
    background-color: #e5e6e7;
  }
  &.active-label {
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }
}

.group-label {
  padding: 2px 12px;
  border-radius: 2px;
  background-color: #ecf8fc;
  font-weight: 400;
  font-size: 12px;
  color: #00a8cd;
  cursor: pointer;
  &.selector:hover {
    background-color: #e5e6e7;
  }
  &.active-label {
    background-color: #00a8cd;
    color: #fff;
  }
}

.record-highlight {
  background-color: #ffdd82;
  color: #000;
}
// 患者搜索高亮
.search-highlight {
  background: #ffdd82;
  border: 1px solid #ffad0d;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 5px 8px;
}


.spin.ant-spin-nested-loading > div > .ant-spin {
    max-height: 100% !important;
}

.tooltip-opt {
  height: 342px;
  width: 320px;
  overflow: auto;
}
