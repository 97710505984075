
.flowWrapper {
    display: flex;
    flex:1
}

.canvasWrapper {
    display: flex;
    flex-direction: row;
    //height: calc(100vh - 108px - 48px);
    .canvas {
        display: flex;
        flex:1;
        flex-direction: column;
    }

}
.canvasProps {
    display: flex;
    width: 460px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
    .canvasPropsTitle {
        height: 56px;
        display: flex;
        flex-direction: row;
        //justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
    }
    .dragList {
        padding-top: 24px;
        height: calc(100vh - 108px - 48px - 72px);
        overflow: scroll;
    }
    .dragListEmpty {
        padding-top: 24px;
        height: calc(100vh - 108px - 48px - 72px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* 画布组件样式 Start */
.diagram-component {
    width: 100%;
    height: calc(100vh);
    border: 1px solid #d9d9d9;
    background-color: white;
}


.itemWrapper {
    userSelect: 'none';
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
    border: 1px solid #F0F0F0;

    .itemTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 8px;
        height: 32px;
    }

    .formWrapper {
        padding: 8px;
    }
}
