.btn {
  text-align: center;
  font-size: 14px;
  color: #031f47;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 14px;
  color: #687991;
  margin-top: 4px;
}

.error {
  color: #f5222d;
}

.warn {
  display: flex;
  align-items: center;
  gap: 8px;
  .txt {
    color: #687991;
  }
}
