.patientRecordHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 22px 0;
  display: none;
}

.tabWrap {
  margin-top: 24px;
}

.selected {
  padding-right: 8px;
  margin-right: 8px;
  max-height: 1000px;
  overflow-y: auto;
  .tabList {
    padding: 12px 16px;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      box-shadow: 0 0 10px #ecfdff;
    }
    .tabLog {
      font-size: 12px;
      color: #1bbee1;
      width: fit-content;
      padding: 2px 12px;
      background: #ecf8f9;
      margin-bottom: 8px;
    }
    .tabItem {
      margin-bottom: 8px;
    }
  }
  .tabActive {
    border: 1px solid #1bbee1;
  }
}

.labelWrap {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  .labelTable {
    padding: 4px 12px;
    margin-right: 16px;
    white-space: nowrap;
  }
}

.hitList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 400px;
  gap: 6px 6px;
}

.hitItem {
  padding: 2px 12px;
  border-radius: 2px;
  background-color: #d7ebf3;
  font-weight: 400;
  font-size: 12px;
  color: #4a4c4e;
  cursor: pointer;
  &.active {
    background-color: #5896b2;
    color: #fff;
  }
}
