
.loading {
  display: flex;
  flex: 1;
  width: 1192px;
  height: calc(100vh - 128px);
  justify-content: center;
  align-items: center;
}
body {
  margin: 0;
  background-color: rgb(82, 86, 89);
  font-family: Segoe UI, Tahoma, sans-serif;
}

.Example {
  input,
  button {
    font: inherit;
  }

  header {
    background-color: rgb(50, 54, 57);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;

    h1 {
      font-size: inherit;
      margin: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;

    &__load {
      margin-top: 1em;
      color: white;
    }

    &__document {
      margin: 1em 0;

      .react-pdf {
        &__Document {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__Page {
          max-width: calc(~'100% - 2em');
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
          margin: 1em;

          canvas {
            max-width: 100%;
            height: auto !important;
          }
        }

        &__message {
          padding: 20px;
          color: white;
        }
      }
    }
  }
}
