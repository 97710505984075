.tagSelect {
  display: inline-flex;
  padding: 4px;
  background: #fafbfb;
  border-radius: 4px;
  gap: 4px;
  .option {
    text-align: center;
    line-height: 22px;
    color: #687991;
    font-weight: 400;
    font-size: 14px;
    padding: 1px 12px;
    cursor: pointer;
    flex-shrink: 0;
    &.active {
      color: #1bbee1;
      background: #ffffff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      font-weight: 500;
    }
  }
}
