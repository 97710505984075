.upload {
  background: #ffffff;
  border: 1px dashed #ced0d6;
  border-radius: 4px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 8px;
  &:hover {
    border: 1px dashed #00afec;
    background: rgba(0, 175, 236, 0.1);
  }
}

.fileContainer {
  width: 100%;
  height: 64px;
  background: #ffffff;
  border: 1px solid #e9eaec;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  .info {
    flex: 1;
    margin-left: 16px;
    .name {
      display: flex;
      align-items: center;
      gap: 5px;
      &.error {
        color: #f5222d;
      }
    }
    .size {
      font-size: 12px;
      color: #a5afbd;
    }
  }
}
