
.filterList {
  width: 140px;
  height: 292px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  position: absolute;
  z-index: 999;
  background: #fff;
}

.filterWrapper {
  width: 140px;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 999;
  background: #fff;

  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.16);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 12px;
}
.self {
  background: #F5F5F5;
  margin-top: 8px;
}

.btnGroup {
  display: flex;
  height: 48px;
  justify-items: center;
  justify-content: flex-end;
  border-top: 1px solid #F0F0F0;
  padding-right: 12px;
  button {
    height: 100%;
    padding: 0;
  }
}