.search {
  padding: 0 0 16px;
  display: flex;
  justify-content: space-between;
}

.searchWidth {
  width: 150px;
}

.spaceTab {
  padding: 0px 20px 28px;
}

.rowSpace {
  padding: 20px 20px 0;
}

.spaceRight {
  display: inline-block;
  padding-right: 30px;
}

.statusGreen {
  color: #28B571;
}

.statusRed {
  color: #F02F2F;
}

.tagBox {
  border: 1px solid #EBEBEB;
  padding: 15px 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.tagIcon {
  font-size: 12px;
  color: #fff;
  padding: 4px 8px;
  background: #00AFEC;
  border-radius: 8px;
  margin-right: 24px;
}

.tagIconBlue {
  color: #00AFEC;
  background: #BFF6FF;
}

.tagIconGreen {
  color: #389E0D;
  background: #D9F7BE;
}

.taglabel {
  font-size: 16px;
  font-weight:bold;
  margin-right: 24px;
  margin-top: -2px;
}
.tagNumbet {
  font-size: 18px;
  font-weight:bold;
  margin-top: -1px;
}

.tab {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-tab {
      margin-left: .32rem;
    }
  }
}

/*
单行显示，超出...
*/
.ellipse {
  width: 150px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.diagramContent {
  display: flex;
  flex-direction: row;
}
